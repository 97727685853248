var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("order.name") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.orderReference,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "orderReference", $$v)
              },
              expression: "listQuery.orderReference"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("user.company"), clearable: "" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery["user.companyName"],
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "user.companyName", $$v)
              },
              expression: "listQuery['user.companyName']"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("order.userName"), clearable: "" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery["user.fullName"],
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "user.fullName", $$v)
              },
              expression: "listQuery['user.fullName']"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("order.mobile"), clearable: "" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery["user.mobileNumber"],
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "user.mobileNumber", $$v)
              },
              expression: "listQuery['user.mobileNumber']"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("order.email"), clearable: "" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery["user.emailAddress"],
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "user.emailAddress", $$v)
              },
              expression: "listQuery['user.emailAddress']"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: { clearable: "", placeholder: "Status" },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.status,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "status", $$v)
                },
                expression: "listQuery.status"
              }
            },
            _vm._l(_vm.statusOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.label, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.sort,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "sort", $$v)
                },
                expression: "listQuery.sort"
              }
            },
            _vm._l(_vm.sortOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.label, value: item.key }
              })
            }),
            1
          ),
          _c("br"),
          _c("VueCtkDateTimePicker", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { range: "" },
            on: { change: _vm.dateval },
            model: {
              value: _vm.dateRange,
              callback: function($$v) {
                _vm.dateRange = $$v
              },
              expression: "dateRange"
            }
          }),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { size: "small", type: "warning", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.resetOrder }
            },
            [_vm._v(" New Order ")]
          ),
          _c(
            "el-dialog",
            {
              attrs: { title: "Select User", visible: _vm.dialogFormVisible },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                }
              }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    "remote-method": _vm.searchUsers,
                    filterable: "",
                    clearable: "",
                    "default-first-option": "",
                    remote: ""
                  },
                  model: {
                    value: _vm.userId,
                    callback: function($$v) {
                      _vm.userId = $$v
                    },
                    expression: "userId"
                  }
                },
                _vm._l(_vm.userList, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: { value: item.id, label: item.fullName }
                    },
                    [
                      _c("span", [
                        _vm._v(" " + _vm._s(item.fullName) + " "),
                        item.companyName
                          ? _c("i", [
                              _vm._v("(" + _vm._s(item.companyName) + ") ")
                            ])
                          : _vm._e()
                      ])
                    ]
                  )
                }),
                1
              ),
              !_vm.orderExists
                ? _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.dialogFormVisible = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.checkInCartOrder(_vm.userId)
                            }
                          }
                        },
                        [_vm._v("Verify ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.orderExists
                ? _c(
                    "el-popconfirm",
                    {
                      attrs: {
                        title:
                          "An order already exists for this user, do you want to proceed?"
                      },
                      on: { confirm: _vm.createOrder, cancel: _vm.resetOrder }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", type: "success" },
                          slot: "reference"
                        },
                        [_vm._v(" Confirm ")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: { "sort-change": _vm.sortChange }
        },
        [
          _c("el-table-column", {
            attrs: {
              width: "200px",
              align: "left",
              label: "Order Status",
              prop: "status"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("strong", [_vm._v("ID: ")]),
                    _c(
                      "router-link",
                      { attrs: { to: "/order/" + scope.row.id } },
                      [
                        scope.row.status !== "in cart"
                          ? _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v(
                                " " + _vm._s(scope.row.orderReference) + " "
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    scope.row.status === "in cart"
                      ? _c("div", [_vm._v(" " + _vm._s(scope.row.id) + " ")])
                      : _vm._e(),
                    _c("br"),
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          size: "mini",
                          type: _vm.statusColorMap[scope.row.status]
                        }
                      },
                      [_vm._v(" " + _vm._s(scope.row.status) + " ")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "250",
              align: "center",
              label: "Products",
              prop: "ordersProduct"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._l(scope.row.ordersProduct, function(product) {
                      return _c(
                        "el-popover",
                        {
                          key: product.id,
                          attrs: {
                            placement: "top-start",
                            width: "400",
                            trigger: "hover"
                          }
                        },
                        [
                          _c("p", [
                            _vm._v("Quantity: " + _vm._s(product.quantity))
                          ]),
                          _c("p", [
                            _vm._v(
                              "Variant: " +
                                _vm._s(product.productsVariation.name)
                            )
                          ]),
                          _c(
                            "p",
                            [
                              _vm._v(" Unit Price: "),
                              _c("inr", {
                                attrs: { number: product.unitPrice }
                              })
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _vm._v(" Sale Price: "),
                              _c("inr", {
                                attrs: { number: product.salePrice }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            { attrs: { slot: "reference" }, slot: "reference" },
                            [_vm._v(" " + _vm._s(product.product.name) + " ")]
                          )
                        ],
                        1
                      )
                    }),
                    _c("br")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "220px",
              align: "left",
              label: "User",
              prop: "userId"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return scope.row.user
                      ? [
                          scope.row.user.companyName
                            ? _c("b", [
                                _c("i", { staticClass: "el-icon-suitcase" }),
                                _vm._v(
                                  " " + _vm._s(scope.row.user.companyName) + " "
                                )
                              ])
                            : _vm._e(),
                          _c("br"),
                          _c("i", { staticClass: "el-icon-user" }),
                          _vm._v(" " + _vm._s(scope.row.user.fullName) + " "),
                          _c("br"),
                          _c("i", { staticClass: "el-icon-mobile-phone" }),
                          _vm._v(
                            " " + _vm._s(scope.row.user.mobileNumber) + " "
                          ),
                          _c("br"),
                          _c("i", { staticClass: "el-icon-message" }),
                          _vm._v(
                            " " + _vm._s(scope.row.user.emailAddress) + " "
                          )
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          }),
          _c("el-table-column", {
            attrs: {
              width: "210px",
              align: "left",
              label: "Grand Total",
              prop: "grandTotal"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(
                    ["total", "taxedAmount", "grandTotal", "amountReceived"],
                    function(field) {
                      return _c(
                        "div",
                        { key: field },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.totalColumn[field]) + ":")
                          ]),
                          _c("inr", { attrs: { number: scope.row[field] } })
                        ],
                        1
                      )
                    }
                  )
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "150px",
              align: "center",
              prop: "orderTimestamp",
              label: "Placed On"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("moment")(
                            scope.row.orderTimestamp,
                            "DD-MM-YYYY hh:mm a"
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              align: "center",
              label: "Actions",
              width: "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    ![
                      "RTGS Payment",
                      "Delivery Confirmed",
                      "Order Cancelled",
                      "Partial Dispatch"
                    ].includes(scope.row.status)
                      ? _c(
                          "router-link",
                          { attrs: { to: "/order/edit/" + scope.row.id } },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                size: "small",
                                icon: "el-icon-edit"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "Dispatch Order",
                          placement: "top"
                        }
                      },
                      [
                        [
                          "Order Placed",
                          "Order Cancelled",
                          "Partial Dispatch"
                        ].includes(scope.row.status)
                          ? _c("el-button", {
                              staticStyle: { "margin-left": "unset" },
                              attrs: {
                                type: "warning",
                                size: "small",
                                icon: "el-icon-upload2"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.dispatchOrder(scope.row.id)
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    scope.row.status === "Delivery Confirmed"
                      ? _c(
                          "router-link",
                          { attrs: { to: "/order/ratings/" + scope.row.id } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: "Manage Ratings",
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      { name: "waves", rawName: "v-waves" }
                                    ],
                                    attrs: { type: "warning", size: "small" }
                                  },
                                  [_c("i", { staticClass: "el-icon-star-on" })]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      { attrs: { to: "/transaction/list/" + scope.row.id } },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: "View Transactions",
                              placement: "top-start"
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  { name: "waves", rawName: "v-waves" }
                                ],
                                attrs: { type: "info", size: "small" }
                              },
                              [_c("i", { staticClass: "el-icon-bank-card" })]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    ["in cart", "Order Placed"].includes(scope.row.status)
                      ? _c(
                          "router-link",
                          { attrs: { to: "/order/modify/" + scope.row.id } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: "Modify Order",
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      { name: "waves", rawName: "v-waves" }
                                    ],
                                    attrs: { type: "success", size: "small" }
                                  },
                                  [_c("i", { staticClass: "el-icon-setting" })]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }